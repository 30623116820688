export const state = () => ({
  loading: true,
  phone: "",
  cpf: ""
})

export const getters = {
  getPhone(state) {
    return state.phone
  }
}

export const mutations = {
  setWhatsapp(state, { cpf, phone }) {
    state.cpf = cpf;
    state.phone = phone;
    state.loading = false;
  }
}
